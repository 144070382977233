import { mapLink } from './mapLink';
import {
  IBentoboxSectionEntry,
  IBentoboxSection,
} from '../types/IBentoboxSection';
import { MappedImage } from 'src/features/pg-funnel/services/contentful/types';
import mapImage from 'src/services/contentful/utils/map-image';
import { mapVideoAsset } from 'src/services/contentful/utils/map-video-asset';

export function mapBentoboxSection(
  fields: IBentoboxSectionEntry['fields'],
): IBentoboxSection {
  return {
    title: fields.title,
    description: fields.description ?? undefined,
    items: fields.items
      .map((item) => {
        console.log(item);
        if (item.fields.file.contentType.startsWith('video')) {
          return mapVideoAsset(item);
        }

        if (item.fields.file.contentType.startsWith('image')) {
          return mapImage(item) as MappedImage;
        }
      })
      .filter(Boolean) as [],
    button:
      fields.buttonLink && fields.buttonLabel
        ? mapLink(fields.buttonLink, fields.buttonLabel)
        : undefined,
  };
}
