import {
  getClubOpeningHoursByClubId,
  getClubOpeningHoursExceptionsByClubId,
} from '@services/proxy';
import { createClient } from 'contentful';
import stringify from 'json-stringify-safe';
import mapCheckout from 'services/contentful/utils/map-checkout';
import { mapClubPage } from 'services/contentful/utils/map-club-page';
import mapClubPages from 'services/contentful/utils/map-club-pages';
import { mapLanguages } from 'services/contentful/utils/map-languages';
import { mapMenus } from 'services/contentful/utils/map-menus';
import { mapClubCards } from 'src/services/contentful/utils/map-club-card';
import * as Constants from './constants';
import { mapPage } from './utils/map-page';

let publicClient;
let previewClient;

export function getClient({ isPreview = false } = {}) {
  const space = process.env.NEXT_PUBLIC_CMS_SPACE_ID;
  const environment = process.env.NEXT_PUBLIC_CMS_ENVIRONMENT;

  if (isPreview) {
    previewClient =
      previewClient ||
      createClient({
        space,
        environment,
        host: process.env.NEXT_PUBLIC_CMS_HOST_PREVIEW,
        accessToken: process.env.NEXT_PUBLIC_CMS_PREVIEW_TOKEN,
      });
    return previewClient;
  } else {
    publicClient =
      publicClient ||
      createClient({
        space,
        environment,
        host: process.env.NEXT_PUBLIC_CMS_HOST,
        accessToken: process.env.NEXT_PUBLIC_CMS_TOKEN,
        removeUnresolved: true,
      });
    return publicClient;
  }
}

export async function fetchPage(slug, lang, isPreview) {
  let { items } = await getClient({ isPreview }).getEntries({
    content_type: Constants.CONTENT_TYPES.PAGE,
    limit: 1,
    include: 5,
    locale: lang,
    'fields.slug': slug,
  });

  // Remove circular structure from Contentful
  items = JSON.parse(stringify(items));

  let data = items[0];

  if (!data) {
    let { items } = await getClient({ isPreview }).getEntries({
      content_type: Constants.CONTENT_TYPES.CLUB_PAGE,
      limit: 1,
      include: 5,
      locale: lang,
      'fields.slug': slug,
    });

    // Remove circular structure from Contentful
    items = JSON.parse(stringify(items));

    data = items[0];

    if (data) {
      const openingHours = await getClubOpeningHoursByClubId(
        data.fields.pgClubId,
      );
      const openingHoursExceptions =
        await getClubOpeningHoursExceptionsByClubId(data.fields.pgClubId);

      data.fields.openingHours = openingHours.data;
      data.fields.openingHoursExceptions = openingHoursExceptions.data;
    }
  }
  if (!data) {
    const error = new Error(`page with slug ${slug} not found`);
    error.statusCode = 404;
    throw error;
  }
  if (data.sys.contentType.sys.id === Constants.CONTENT_TYPES.PAGE) {
    const languages = await getAlternativeLanguages(
      data.sys.id,
      Constants.CONTENT_TYPES.PAGE,
      isPreview,
    );
    return mapPage({ ...data, languages });
  }
  if (data.sys.contentType.sys.id === Constants.CONTENT_TYPES.CLUB_PAGE) {
    const languages = await getAlternativeLanguages(
      data.sys.id,
      Constants.CONTENT_TYPES.CLUB_PAGE,
      isPreview,
    );
    return mapClubPage({ ...data, languages });
  }
}

export async function fetchClubCards() {
  let { items } = await getClient().getEntries({
    content_type: Constants.CONTENT_TYPES.CLUB_PAGE,
    limit: 1000,
    include: 3,
    select: [
      'fields.title',
      'fields.slug',
      'fields.pgClubId',
      'fields.stagingClubId',
      'fields.clubHeaderMedia',
      'fields.usps',
      // 'fields.contentBlocks', temporarily removed for fixing https://olympusinvestment.atlassian.net/browse/SC-3730
    ].join(','),
  });
  // Remove circular structure from Contentful
  items = JSON.parse(stringify(items));

  const data = items;
  if (!data) {
    const error = new Error('page not found');
    error.statusCode = 404;
    throw error;
  }
  return mapClubCards(data);
}

export async function fetchClubPages() {
  let { items } = await getClient().getEntries({
    content_type: Constants.CONTENT_TYPES.CLUB_PAGE,
    limit: 1000,
    include: 3,
  });

  // Remove circular structure from Contentful
  items = JSON.parse(stringify(items));

  const data = items;
  if (!data) {
    const error = new Error('page not found');
    error.statusCode = 404;
    throw error;
  }
  return mapClubPages(data);
}

export async function fetchMenus(lang, isPreview) {
  const client = await getClient({ isPreview });

  // Only fetch primary and secondary menus: others are fetched somewhere else
  const { items: primaryItems } = await client.getEntries({
    content_type: Constants.CONTENT_TYPES.MENU,
    locale: lang,
    include: 3,
    'fields.location': 'Header Primary Menu',
  });

  const { items: secondaryItems } = await client.getEntries({
    content_type: Constants.CONTENT_TYPES.MENU,
    locale: lang,
    include: 3,
    'fields.location': 'Header Secondary Menu',
  });

  // Remove circular structure from Contentful
  const items = JSON.parse(stringify([...primaryItems, ...secondaryItems]));

  const data = items;
  if (!data) {
    const error = new Error('menu not found');
    error.statusCode = 404;
    throw error;
  }

  return mapMenus(data);
}

export async function fetchAllPageUrls() {
  const { items } = await getClient().getEntries({
    content_type: Constants.CONTENT_TYPES.PAGE,
    locale: '*',
    limit: 1000, // the max contentful can handle
    select: 'fields.slug',
    'fields.excludeFromSitemap[ne]': true,
  });

  return mapLanguages(items);
}

export async function fetchAllClubPageUrls() {
  const { items } = await getClient().getEntries({
    content_type: Constants.CONTENT_TYPES.CLUB_PAGE,
    locale: '*',
    limit: 1000, // the max contentful can handle
    select: 'fields.slug',
    'fields.excludeFromSitemap[ne]': true,
  });

  return mapLanguages(items);
}

export async function getAlternativeLanguages(id, contentType, isPreview) {
  return getClient({ isPreview }).getEntry(id, {
    content_type: contentType,
    locale: '*',
    select: 'sys.id,fields.slug',
  });
}

export async function fetchCheckout(
  lang,
  isAlternative,
  promoQueryParameter,
  isPreview,
) {
  let { items } = await getClient({ isPreview }).getEntries({
    content_type: Constants.CONTENT_TYPES.CHECKOUT,
    limit: 1,
    include: 5,
    locale: lang,
    'fields.alternative': isAlternative,
    'fields.queryParameter': promoQueryParameter || '',
    'fields.isPromo': promoQueryParameter !== undefined || false,
  });

  // Remove circular structure from Contentful
  items = JSON.parse(stringify(items));

  const data = items[0];
  if (!data) {
    const error = new Error('checkout not found');
    error.statusCode = 404;
    throw error;
  }
  return mapCheckout(data);
}
