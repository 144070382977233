const CONTENT_TYPES = {
  PAGE: 'contentPage',
  CLUB_PAGE: 'clubPage',
  MENU: 'menu',
  CHECKOUT: 'checkout',
  PAYMENT_PAGES: 'paymentPages',
  REDIRECT: 'redirect',
};

module.exports = {
  CONTENT_TYPES,
};
