import { fetchMenus as fetchMenusProxy } from '@features/shared/services/proxy/fetchMenus';
import sassBreakpoints from 'assets/scss/breakpoints.module.scss';
import 'assets/scss/global.scss';
import { PageLoadingIndicator } from 'features/shared/components/page-loading-indicator';
import I18nProvider from 'domains/i18n/provider';
import createStore from 'domains/redux/create-store';
import { enableES5 } from 'immer';
import App from 'next/app';
import PropTypes from 'prop-types';
import React from 'react';
import { Hydrate, QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Provider as ReduxProvider } from 'react-redux';
import { END as END_SAGAS } from 'redux-saga';
import { ErrorHandler } from 'src/features/error-page/components/error-handler';
import { filterSiteMenus } from 'src/features/layout/menu/utils/filterSiteMenus';
import { mapSiteMenus } from 'src/features/layout/menu/utils/mapSiteMenus';
import { ErrorBoundary } from 'src/features/shared/components/error-boundary';
import { TooltipProvider } from 'src/features/shared/components/tooltip/Tooltip';
import { deepRemoveUndefined } from 'src/features/shared/utils/deep-remove-undefined';
import { fetchPage } from 'src/services/contentful';
import { BreakpointsContext } from 'utils/hooks/use-breakpoints';
import { LangContextProvider } from 'utils/hooks/use-lang';
import isClient from 'utils/is-client';
import { dinNext, hackney } from 'src/config/fonts';

const breakpoints = Object.entries(sassBreakpoints).reduce(
  (breakpoints, [name, value]) => {
    return {
      ...breakpoints,
      [name]: {
        name,
        value: parseInt(value),
      },
    };
  },
  {},
);

enableES5();

export default class MyApp extends React.Component {
  queryClient = new QueryClient();

  static getInitialProps = async ({ Component, ctx, router, lang }) => {
    const { store, sagaTask } = createStore({ runSagas: true });
    const { res } = ctx;

    const { locale } = router;

    ctx.store = store;
    ctx.lang = locale;
    let error;
    let appProps = {};

    try {
      appProps = await App.getInitialProps({ Component, ctx });
    } catch (e) {
      console.log(e);
      error = e;
    }

    if (!isClient) {
      try {
        store.dispatch(END_SAGAS);
        await sagaTask.toPromise();
      } catch (e) {
        // todo: set error
      }
    }

    let menus = null;
    if (error && res) {
      res.statusCode = error?.statusCode || 500;
      const resMenus = await fetchMenusProxy();
      menus = mapSiteMenus(filterSiteMenus(resMenus));
    }

    return {
      ...appProps,
      error,
      menus,
      statusCode: res?.statusCode,
      initialState: store.getState(),
    };
  };

  static propTypes = {
    Component: PropTypes.func.isRequired,
    pageProps: PropTypes.object.isRequired,
    error: PropTypes.object,
    initialState: PropTypes.object,
    router: PropTypes.object,
    statusCode: PropTypes.number,
    menus: PropTypes.object,
  };

  constructor(props, context) {
    super(props, context);
    const { store } = createStore({
      runSagas: isClient,
      initialState: props.initialState,
    });
    this.store = {
      ...store,
    };
  }

  render() {
    const { Component, pageProps, error, statusCode, menus } = this.props;

    return (
      <>
        <style jsx global>{`
          :root {
            --font-din: ${dinNext.style.fontFamily};
            --font-hackney: ${hackney.style.fontFamily};
          }
        `}</style>
        <ErrorBoundary>
          <I18nProvider>
            <LangContextProvider>
              <ReduxProvider store={this.store}>
                <QueryClientProvider client={this.queryClient}>
                  <Hydrate state={pageProps?.dehydratedState}>
                    <BreakpointsContext.Provider value={breakpoints}>
                      <TooltipProvider>
                        <PageLoadingIndicator />
                        {error ? (
                          <ErrorHandler statusCode={statusCode} menus={menus} />
                        ) : (
                          <Component {...pageProps} />
                        )}
                      </TooltipProvider>
                    </BreakpointsContext.Provider>
                  </Hydrate>
                  <ReactQueryDevtools />
                </QueryClientProvider>
              </ReduxProvider>
            </LangContextProvider>
          </I18nProvider>
        </ErrorBoundary>
      </>
    );
  }
}
