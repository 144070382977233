import { Asset } from 'contentful';
import { IVideo } from 'src/features/shared/contentful/types/IVideo';

export function mapVideoAsset(data: Asset): IVideo {
  const formattedVideoUrl = `https://${data.fields.file.url.replace(
    /^\/\//,
    '',
  )}`;

  return {
    url: formattedVideoUrl,
    title: data.fields.title,
    type: data.fields.file.contentType,
    fileName: data.fields.file.fileName,
  };
}
